<template>
  <configurable-table
    :report="report"
  >
    <template #total>
      <a-dashboard
        :api="capital.getWaitSettleInfo"
        :options="[
          {
            title: '待结算货款总额',
            key: 'waitSettleGoodAmount',
          },
          {
            title: '待结算佣金总额',
            key: 'waitSettleCommissionAmount'
          }
        ]"
      />
    </template>
  </configurable-table>
</template>

<script>
import { capital } from '@/api'
import ConfigurableTable from './ConfigurableTable'
import { Report } from './report'
import ADashboard from './ADashBoard'
import moment from 'moment'
import { createMapFromList } from '@/utils/tools'
import { settleTransactionTypes, settleTypes } from '@/utils/constants'

export default {
  name: 'PageSettleWait',
  components: {
    ConfigurableTable,
    ADashboard,
  },
  setup () {
    const queryFields = [
      {
        type: 'select',
        key: 'supplyShopId',
        title: '供货商',
        options: async () => {
          const { data } = await capital.getSupplyShopOfMine()
          return data.map(x => ({
            name: x.name,
            value: x.shopId
          }))
        },
      },
      {
        type: 'input',
        key: 'orderNo',
        title: '订单号',
      },
      {
        type: 'select',
        key: 'type',
        title: '结算类型',
        options: settleTypes
      },
      {
        type: 'select',
        key: 'transactionType',
        title: '交易类型',
        options: settleTransactionTypes
      },
      {
        type: 'daterange',
        key: 'range',
        title: '交易日期',
      },
    ]

    const resultFields = [
      {
        key: 'transactionTime',
        title: '交易日期',
        transform: x => moment(x).format('YYYY-MM-DD')
      },
      {
        key: 'shopName',
        title: '供货商',
      },
      {
        key: 'type',
        title: '结算类型',
        transform: x => createMapFromList(settleTypes)[x]
      },
      {
        key: 'transactionType',
        title: '交易类型',
        transform: x => createMapFromList(settleTransactionTypes)[x]
      },
      {
        key: 'productName',
        title: '商品名称'
      },
      {
        key: 'orderNo',
        title: '订单编号'
      },
      {
        key: 'count',
        title: '数量'
      },
      {
        key: 'settleAmount',
        title: '订单金额'
      },
      {
        key: 'supplyPrice',
        title: '成本价'
      },
      {
        key: 'logisticsAmount',
        title: '运费'
      },
      {
        key: 'tradePrice',
        title: '供货价'
      },
      {
        key: 'serviceFee',
        title: '平台服务费'
      },
      {
        key: 'realAmount',
        title: '待结算金额'
      }
    ]

    return {
      capital,
      report: new Report(queryFields, resultFields, {
        key: 'orderIncomeId',
        queryApi: ({ range, ...others }) => {
          return capital.getWaitSettlePage({
            ...others,
            ...(range.length ? {
              transactionBeginTime: range[0].format('YYYY-MM-DD 00:00:00'),
              transactionEndTime: range[1].format('YYYY-MM-DD 23:59:59')
            } : null)
          })
        },
        exportApi: ({ range, ...others }) => {
          return capital.exportWaitSettle({
            ...others,
            ...(range.length ? {
              transactionBeginTime: range[0].format('YYYY-MM-DD 00:00:00'),
              transactionEndTime: range[1].format('YYYY-MM-DD 23:59:59')
            } : null)
          })
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>
