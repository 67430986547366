var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('configurable-table',{attrs:{"report":_vm.report},scopedSlots:_vm._u([{key:"total",fn:function(){return [_c('a-dashboard',{attrs:{"api":_vm.capital.getWaitSettleInfo,"options":[
        {
          title: '待结算货款总额',
          key: 'waitSettleGoodAmount',
        },
        {
          title: '待结算佣金总额',
          key: 'waitSettleCommissionAmount'
        }
      ]}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }